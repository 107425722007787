<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'meal.restrictions' }"
          >Meal Restriction</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="form">
      <v-form @submit.prevent="updateMealRestriction" ref="form">
        <v-row>
          <v-col cols="4">
            <v-text-field
              outlined
              required
              label="Meal Restriction Name"
              hide-details="auto"
              v-model="form.name"
              :error-messages="form.$getError('name')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              outlined
              label="Type"
              hide-details="auto"
              :items="restriction_type_items"
              persistent-placeholder
              v-model="form.type"
              :error-messages="form.$getError('type')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              outlined
              required
              label="Initial"
              hide-details="auto"
              v-model="form.initial"
              :error-messages="form.$getError('initial')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn type="submit" color="primary" :loading="form.$busy"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ControlsMixin from '@/utils/mixins/Controls'
import { mdiArrowLeft } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import Form from '@/utils/form'

export default {
  name: 'MealRestrictionDetailsPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      form: null,
      icons: {
        arrowLeft: mdiArrowLeft
      },
      restriction_type_items: [
        { text: 'Allergy', value: 'allergy' },
        { text: 'Dietary', value: 'dietary' }
      ],
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  computed: {
    ...mapState({
      restriction: state => state.restriction.mealRestrictionDetails
    })
  },

  methods: {
    ...mapActions({
      getMealRestrictionDetails: 'restriction/getMealRestrictionDetails',
      updateMealRestrictionDetails: 'restriction/updateMealRestrictionDetails'
    }),

    ...mapMutations({
      clearMealRestrictionDetails: 'restriction/clearMealRestrictionDetails'
    }),

    async getMealRestriction() {
      await this.getMealRestrictionDetails(this.$route.params.id)
      this.form = new Form(this.restriction)
    },

    updateMealRestriction() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateMealRestrictionDetails(this.form.$data())
        .then(() => {
          this.showSnackbar(
            'Meal Restriction details successfully updated!',
            'success'
          )
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  created() {
    this.getMealRestriction()
  },

  destroyed() {
    this.clearMealRestrictionDetails()
  },

  watch: {
    $route() {
      this.getMealRestriction()
    }
  }
}
</script>
